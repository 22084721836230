/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DashboardService from '../services/DashboardService';

export const getDashboardStats = createAsyncThunk(
  'dashboard/getDashboardStats',
  async () => {
    try {
      const response = await DashboardService.getDashboardStats();
      const data = response.data;
      return data;
    } catch (e) {
      throw new Error('There was an error while retrieve the dashboard stats');
    }
  }
);

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    error: null,
    data: [],
    showLeftBar: false,
  },
  reducers: {
    toggleLeftBar: (state) => ({
      ...state,
      showLeftBar: !state.showLeftBar,
    }),
  },
  extraReducers: {
    [getDashboardStats.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardStats.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getDashboardStats.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { toggleLeftBar } = DashboardSlice.actions;

export default DashboardSlice.reducer;
