/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LocationVehicleService from "../services/LocationVehicleService";
import { toast } from "react-toastify";

export const createLocationVehicle = createAsyncThunk(
  "locationVehicles/createLocationVehicle",
  async ({
    plateNumber,
    stateProvince,
    description,
    listType,
    locations,
    vehicleId,
    operators,
  }) => {
    try {
      const response = await LocationVehicleService.createLocationVehicle({
        plateNumber,
        stateProvince,
        description,
        listType,
        locations,
        vehicleId,
        operators,
      });
      if (response?.data) {
        const data = await response.data;
        if (vehicleId) {
          data["vehicleId"] = vehicleId;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const uploadVehiclesCsv = createAsyncThunk(
  "locationVehicles/uploadVehiclesCsv",
  async ({
    fileData,
    operators,
    locations,
    csvType,
  }) => {
    try {
      const response = await LocationVehicleService.uploadVehiclesCsv({
        fileData,
        operators,
        locations,
        csvType,
      });
      if (
        typeof response !== "undefined" &&
        typeof response.data !== "undefined"
      ) {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getAllVehicles = createAsyncThunk(
  "locationVehicles/getAllVehicles",
  async () => {
    const response = await LocationVehicleService.getAllVehicles();
    const data = response.data;
    return data;
  }
);
export const getLocationVehicle = createAsyncThunk(
  "locationVehicles/getLocationVehicle",
  async (vehicleId) => {
    if (typeof vehicleId !== "undefined") {
      const response = await LocationVehicleService.getLocationVehicle(
        vehicleId
      );
      const data = response.data;
      return data;
    }
  }
);

export const deleteLocationVehicle = createAsyncThunk(
  "locationVehicles/deleteLocationVehicle",
  async (vehicle) => {
    const { id: vehicleId } = vehicle;
    const response = await LocationVehicleService.deleteLocationVehicle(
      vehicleId
    );
    const data = response.data;
    data["vehicleId"] = vehicleId;
    return data;
  }
);
export const updateVehicleListType = createAsyncThunk(
  "locationVehicles/updateVehicleListType",
  async ({ vehicleId, list_type }) => {
    const response = await LocationVehicleService.updateVehicleListType(
      vehicleId,
      { list_type }
    );
    const data = response.data;
    data["vehicleId"] = vehicleId;
    return data;
  }
);

export const locationVehiclesSlice = createSlice({
  name: "locationVehicles",
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  extraReducers: {
    [createLocationVehicle.pending]: (state) => {
      state.loading = true;
    },
    [createLocationVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createLocationVehicle.fulfilled]: (state, action) => {
      if (typeof action.payload.vehicleId !== "undefined") {
        state.loading = false;
        let locationVehicles = state.data.filter(
          (location) => location.id !== action.payload.vehicleId
        );
        locationVehicles.push(action.payload);
        state.data = locationVehicles;
      } else if (typeof action.payload.message !== "undefined") {
        state.loading = false;
      } else {
        state.loading = false;
        state.data.push(action.payload);
      }
    },
    [uploadVehiclesCsv.pending]: (state) => {
      state.loading = true;
    },
    [uploadVehiclesCsv.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [uploadVehiclesCsv.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getAllVehicles.pending]: (state) => {
      state.loading = true;
    },
    [getAllVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getAllVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.allVehicles = action.payload;
    },
    [getLocationVehicle.pending]: (state) => {
      state.loading = true;
    },
    [getLocationVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getLocationVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.locationVehicle = action.payload;
    },
    [deleteLocationVehicle.pending]: (state) => {
      state.loading = true;
    },
    [deleteLocationVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteLocationVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = state?.data?.filter(
        (vehicle) => vehicle.id !== action.payload.vehicleId
      );
      toast.success("Vehicle deleted!");
    },
    [updateVehicleListType.pending]: (state) => {
      state.loading = true;
    },
    [updateVehicleListType.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateVehicleListType.fulfilled]: (state, action) => {
      if (
        typeof action?.payload?.id !== "undefined" &&
        action?.payload?.id !== null
      ) {
        state.loading = false;
        state.locationVehicle = action.payload;
        toast.success("Vehicle type updated!");
      } else {
        state.loading = false;
        toast.error(action.error.message);
      }
    },
  },
});

export default locationVehiclesSlice.reducer;
