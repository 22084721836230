/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PaymentGatewayService from "../services/paymentGatewayService";

export const getDefaultPaymentGateway = createAsyncThunk(
  "paymentGateways/getDefaultPaymentGateway",
  async () => {
    const response = await PaymentGatewayService.getDefaultPaymentGateway();
    const data = response.data;
    return data;
  }
);

export const paymentGatewaySlice = createSlice({
  name: "paymentGateways",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [getDefaultPaymentGateway.pending]: (state) => {
      state.loading = true;
    },
    [getDefaultPaymentGateway.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getDefaultPaymentGateway.fulfilled]: (state, action) => {
      state.loading = false;
      state.defaultPaymentGateway = action.payload;
    },
  },
});

export default paymentGatewaySlice.reducer;
