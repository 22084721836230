import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif; 
    font-style: normal;
  }
  body {
    background: #efefef;
  }
`;

export const AppContainer = styled.div`
  max-width: 1600px;
  margin: auto;
  background: #fff;
`;

export const InterStateWrapper = styled.div.attrs({ 'className': 'interstate-wrapper' })`
    @media (max-width:991px){
        .container{
          max-width:100%;
          padding:0px 30px;
        }
    }
`;
