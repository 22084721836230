import ApiService from './ApiService';

export default class UserService {
  static createUserService = (
    email,
    first_name,
    last_name,
    nonEmail,
    password,
    contact_no,
    role_name,
    operator_ids,
    location_ids,
    userId,
    platform
  ) => {
    let mappedData = {
      user: {
        email,
        first_name,
        last_name,
        password,
        contact_no,
        platform
      },
      non_email: nonEmail,
      operator_ids: operator_ids ? (typeof operator_ids[0] !== 'undefined') ? operator_ids.map((op) => op.value) : [operator_ids.value] : null,
      location_ids: location_ids ? (typeof location_ids[0] !== 'undefined') ? location_ids.map((loc) => loc.value) : [location_ids.value] : null,
      role_name
    };
    if (typeof userId !== 'undefined') {
      return ApiService.put(`/users/${userId}`, mappedData);
    } else {
      return ApiService.post('/invite', mappedData);
    }
  };
  static getUsers = async (filters) => ApiService.get('/users', filters);

  static getUser = async (userId) => ApiService.get(`/users/${userId}`);

  static getUserRoles = async (userId) => ApiService.get(`/users/${userId}/roles`);

  static createUser = async (user) => ApiService.post('/registration', user);

  static resetUser = async (user) => typeof user.user.email !== 'undefined' ? ApiService.post('/reset_password', user) : ApiService.put('/reset_password', user);

  static changePassword = async (password) => ApiService.put('user/change_password', password);

  /**
   * Update the account level roles for a user
   *
   * @param {string} userId
   * @param {object{}} data
   * @returns
   */
  static updateAccountPassword = async (userId, data) => ApiService.patch(`/users/${userId}/update-password`, data);

  static updateAccountUser = async (userId, data) => ApiService.patch(`/users/${userId}`, data);

  static deleteUser = (id) =>
    ApiService.delete(`/users/${id}`);

  static getUserAllRoles = async (filters) => ApiService.get('/roles', filters);

  static acceptInvite = async (payload) => ApiService.post(`/accept_invitation`, payload);

  static getAccountsHistory = async ({ accountsId, timezoneOffset }) => ApiService.get(`users/changes_history/${accountsId}?timezone_offset=${timezoneOffset}`);

  static getParkerTransactionHistory = async () => ApiService.get("user/transactions");
}