import ApiService from "./ApiService";

export default class ParkerVehiclesService {
  static createParkerVehicle = ({
    user_id,
    license_plate_number,
    vehicle_color,
    state,
    vehicle_model,
    vehicleId,
    default_vehicle
  }) => {
    let mappedData = {
      user_id,
      license_plate_number,
      vehicle_color,
      state,
      vehicle_model,
      vehicleId,
      default : default_vehicle
    };
    if (typeof vehicleId !== 'undefined') {
      return ApiService.put(`/parker_vehicles/${vehicleId}`, mappedData);
    } else {
      return ApiService.post("/parker_vehicles", mappedData);
    }
  }

  static getUserSpecificParkerVehicles = async (userId) =>
    ApiService.get(`user/parker_vehicles/${userId}`);

  static getCurrentUserVehicles = async () =>
    ApiService.get("user/parker_vehicles");

  static deleteVehicle = (vehicleId) =>
    ApiService.delete(`/parker_vehicles/${vehicleId}`);

  static getVehicle = (vehicleId) =>
    ApiService.get(`/parker_vehicles/${vehicleId}`);
}
