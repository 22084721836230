/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ElavonService from "../services/ElavonService";

export const getSessionToken = createAsyncThunk(
  "elavon/getSessionToken",
  async ({
    ssl_amount,
    ssl_transaction_type,
    ssl_get_token,
    ssl_add_token,
    ssl_googlepay_merchant_id
  }) => {
    try {
      const response = await ElavonService.getSessionToken({
        ssl_amount,
        ssl_transaction_type,
        ssl_get_token,
        ssl_add_token,
        ssl_googlepay_merchant_id
      });
      const data = await response.data;
      if (response.status !== 200) {
        throw new Error(data.failure);
      }
      return data;
    } catch (e) {
      console.log("error", e);
      throw new Error("An error Occured. Try again later");
    }
  }
);

export const getCurrentUserParkerCards = createAsyncThunk(
  "elavon/getCurrentUserParkerCards",
  async () => {
    const response = await ElavonService.getCurrentUserParkerCards();
    const data = response.data;
    return data;
  }
);

export const addParkerCard = createAsyncThunk(
  "elavon/addParkerCard",
  async (payload) => {
    const response = await ElavonService.addParkerCard(payload);
    const data = response.data;
    return data;
  }
);

export const getCurrentParkerCard = createAsyncThunk(
  "elavon/getCurrentParkerCard",
  async ({ id, ssl_token }) => {
    const response = await ElavonService.getCurrentParkerCard({
      id,
      ssl_token,
    });
    const data = response.data;
    return data;
  }
);

export const editParkerCard = createAsyncThunk(
  "elavon/editParkerCard",
  async ({ id, ssl_token, updatedData }) => {
    try {
      const response = await ElavonService.editParkerCard({
        id,
        ssl_token,
        updatedData,
      });
      const data = await response.data;
      return data;
    } catch (e) {
      throw new Error(e.response.data || "An error occurred. Try again later");
    }
  }
);

export const deleteCurrentUserParkerCard = createAsyncThunk(
  "elavon/deleteCurrentUserParkerCard",
  async ({ id, ssl_token }) => {
    const response = await ElavonService.deleteCurrentUserParkerCard({
      id,
      ssl_token,
    });
    const data = response.data;
    return data;
  }
);

export const processOneTimePayment = createAsyncThunk(
  "elavon/processOneTimePayment",
  async (payload) => {
    try {
      const response = await ElavonService.processOneTimePayment(payload);
      const data = await response.data;
      return data;
    } catch (e) {
      throw new Error(e.response.data || "An error Occured. Try again later");
    }
  }
);

export const elavonSlice = createSlice({
  name: "elavon",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [getSessionToken.pending]: (state) => {
      state.loading = true;
    },
    [getSessionToken.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getSessionToken.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [addParkerCard.pending]: (state) => {
      state.loading = true;
    },
    [addParkerCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [addParkerCard.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getCurrentParkerCard.pending]: (state) => {
      state.loading = true;
    },
    [getCurrentParkerCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getCurrentParkerCard.fulfilled]: (state, action) => {
      state.loading = false;
      state.parkerCardDetails = action.payload;
    },
    [editParkerCard.pending]: (state) => {
      state.loading = true;
    },
    [editParkerCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [editParkerCard.fulfilled]: (state, action) => {
      state.loading = false;
      const parkerCard = state.parkerCards.findIndex(
        (card) => card.id === action.payload.id
      );
      state.parkerCards[parkerCard] = action.payload;
    },
    [getCurrentUserParkerCards.pending]: (state) => {
      state.loading = true;
    },
    [getCurrentUserParkerCards.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getCurrentUserParkerCards.fulfilled]: (state, action) => {
      state.loading = false;
      state.parkerCards = action.payload?.parker_cards;
    },
    [deleteCurrentUserParkerCard.pending]: (state) => {
      state.loading = true;
    },
    [deleteCurrentUserParkerCard.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteCurrentUserParkerCard.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [processOneTimePayment.pending]: (state) => {
      state.loading = true;
    },
    [processOneTimePayment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [processOneTimePayment.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default elavonSlice.reducer;
