/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PermitGroupService from '../services/PermitGroupService';

export const createPermitGroup = createAsyncThunk(
  'PermitGroups/createPermitGroup',
  async ({
    permitGroupId,
    locationId,
    locationName,
    id,
  }) => {
    try {
      const response = await PermitGroupService.createPermitGroup({
        permitGroupId,
        locationId,
        locationName,
        id,
      });
      if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
        const data = await response?.data;

        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const uploadCsv = createAsyncThunk(
  'permitGroups/uploadCsv',
  async ({
    fileData,
    csvType
  }) => {
    try {
      const response = await PermitGroupService.uploadCsv({
        fileData, csvType
      });
      if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
        const data = await response.data;
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
)

export const deletePermitGroup = createAsyncThunk(
  'permitGroups/deletePermitGroup',
  async (id) => {
    const response = await PermitGroupService.deletePermitGroup(id);
    const data = response.data;
    // data['id'] = id;
    return data;
  }
)

export const getPermitGroup = createAsyncThunk(
  'permitGroups/getPermitGroup',
  async (id) => {
    const response = await PermitGroupService.getPermitGroup(id);
    const data = response.data;
    return data;
  }
);

export const getPermitGroups = createAsyncThunk(
  'permitGroups/getPermitGroups',
  async () => {
    const response = await PermitGroupService.getPermitGroups();
    const data = response.data;
    return data;
  }
);

export const permitGroupsSlice = createSlice({
  name: 'permitGroups',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  extraReducers: {
    [createPermitGroup.pending]: (state) => {
      state.loading = true;
    },
    [createPermitGroup.rejected]: (state, action) => {
      state.loading = false;
    },
    [createPermitGroup.fulfilled]: (state, action) => {
      if (typeof action.payload.permitGroupId !== 'undefined') {
        state.loading = false;
        let permitGroups = state.data.filter((permitGroup) => permitGroup.id !== action.payload.permitGroupId);
        permitGroups.push(action.payload);
        state.data = permitGroups;
      } else if (typeof action.payload.message !== 'undefined') {
        state.loading = false;
      } else {
        state.loading = false;
        state.data.push(action.payload);
      }
    },
    [uploadCsv.pending]: (state) => {
      state.loading = true;
    },
    [uploadCsv.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [uploadCsv.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deletePermitGroup.pending]: (state) => {
      state.loading = true;
    },
    [deletePermitGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deletePermitGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = state.data.filter((permitGroup) => permitGroup.id !== action.payload.id);
    },
    [getPermitGroup.pending]: (state) => {
      state.loading = true;
    },
    [getPermitGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getPermitGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
    },
    [getPermitGroups.pending]: (state) => {
      state.loading = true;
    },
    [getPermitGroups.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getPermitGroups.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default permitGroupsSlice.reducer;
