import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TransactionService from "../services/TransactionService";

export const getFinanceReport = createAsyncThunk(
  "transactions/getFinanceReport",
  async () => {
    const response = await TransactionService.getFinanceReport();
    return response.data;
  }
);
export const getLocationPerGroup = createAsyncThunk(
  "transactions/getLocationPerGroup",
  async ({ groupId, startDate, endDate }) => {
    const response = await TransactionService.getLocationPerGroup({
      groupId,
      startDate,
      endDate,
    });
    return response.data;
  }
);
export const getTransactionsPerLocation = createAsyncThunk(
  "getTransactionsPerLocation",
  async ({ locationId, startDate, endDate }) => {
    const response = await TransactionService.getTransactionsPerLocation({
      locationId,
      startDate,
      endDate,
    });
    return response.data;
  }
);
export const getLocationTransactionsPerGroup = createAsyncThunk(
  "getLocationTransactionsPerGroup",
  async ({ groupId, startDate, endDate }) => {
    const response = await TransactionService.getLocationTransactionsPerGroup({
      groupId,
      startDate,
      endDate,
    });
    return response.data;
  }
);
export const getStatementPerGroup = createAsyncThunk(
  "transactions/getStatementPerGroup",
  async ({ groupId, startDate, endDate }) => {
    const response = await TransactionService.getStatementPerGroup({
      groupId,
      startDate,
      endDate,
    });
    return response.data;
  }
);
export const getStatementPerLocation = createAsyncThunk(
  "transactions/getStatementPerLocation",
  async ({ locationId, startDate, endDate }) => {
    const response = await TransactionService.getStatementPerLocation({
      locationId,
      startDate,
      endDate,
    });
    return response.data;
  }
);
export const transactionSlice = createSlice({
  name: "transactions",
  initialState: {
    loading: false,
    error: null,
    data: [],
    locationData: [],
    financeReport: [],
    locationTransactions: [],
    statements: [],
    locationStatements: [],
  },
  extraReducers: {
    [getTransactionsPerLocation.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionsPerLocation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getTransactionsPerLocation.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getLocationPerGroup.pending]: (state) => {
      state.loading = true;
    },
    [getLocationPerGroup.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getLocationPerGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.locationData = action.payload;
    },
    [getFinanceReport.pending]: (state) => {
      state.loading = true;
    },
    [getFinanceReport.rejected]: (state, action) => {
      state.loading = false;
      state.action = action.error.message;
    },
    [getFinanceReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.financeReport = action.payload;
    },
    [getLocationTransactionsPerGroup.pending]: (state) => {
      state.loading = true;
    },
    [getLocationTransactionsPerGroup.rejected]: (state, action) => {
      state.loading = false;
      state.action = action.error.message;
    },
    [getLocationTransactionsPerGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.locationTransactions = action.payload;
    },
    [getStatementPerGroup.pending]: (state) => {
      state.loading = true;
    },
    [getStatementPerGroup.rejected]: (state, action) => {
      state.loading = false;
      state.action = action.error.message;
    },
    [getStatementPerGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.statements = action.payload;
    },
    [getStatementPerLocation.pending]: (state) => {
      state.loading = true;
    },
    [getStatementPerLocation.rejected]: (state, action) => {
      state.loading = false;
      state.action = action.error.message;
    },
    [getStatementPerLocation.fulfilled]: (state, action) => {
      state.loading = false;
      state.locationStatements = action.payload;
    },
  },
});

export default transactionSlice.reducer;
