/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import StripeService from '../services/StripeService';

export const getPaymentIntent = createAsyncThunk(
  'stripe/getPaymentIntent',
  async ({ amount, subscription, slug, state, vehicle_license_number, location_id }) => {
    try {
      const response = await StripeService.getPaymentIntent({
        amount,
        subscription,
        slug,
        state,
        vehicle_license_number,
        location_id
      });
      const data = await response.data;
      if (response.status !== 200) {
        throw new Error(data.failure);
      }
      return data;
    } catch (e) {
      throw new Error('An error Occured. Try again later');
    }
  }
);

export const stripeSlice = createSlice({
  name: 'stripe',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [getPaymentIntent.pending]: (state) => {
      state.loading = true;
    },
    [getPaymentIntent.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getPaymentIntent.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default stripeSlice.reducer;
