import ApiService from "./ApiService";
export default class TransactionService {
  static getFinanceReport = async () =>
    ApiService.get("payments/finance_report");
  static getLocationPerGroup = async ({ groupId, startDate, endDate }) =>
    ApiService.get(
      `/payments/transactions_per_group/${groupId}?start_date=${startDate}&end_date=${endDate}`
    );

  static getTransactionsPerLocation = async ({
    locationId,
    startDate,
    endDate,
  }) =>
    ApiService.get(
      `/transactions_per_location/${locationId}?start_date=${startDate}&end_date=${endDate}`
    );
  static getLocationTransactionsPerGroup = async ({
    groupId,
    startDate,
    endDate,
  }) =>
    ApiService.get(
      `/payments/locations_transactions/${groupId}?start_date=${startDate}&end_date=${endDate}`
    );
  static getStatementPerGroup = async ({ groupId, startDate, endDate }) =>
    ApiService.get(
      `/payments/statement?group_id=${groupId}&start_date=${startDate}&end_date=${endDate}`
    );
  static getStatementPerLocation = async ({ locationId, startDate, endDate }) =>
    ApiService.get(
      `/payments/statement?location_id=${locationId}&start_date=${startDate}&end_date=${endDate}`
    );
}
