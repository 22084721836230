/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClubPriceScalesService from "../services/ClubPriseScalesService";

export const createClubPriceScale = createAsyncThunk(
  "clubPriceScales/createClubPriceScale",
  async ({ operatorId, price_scale_id, clubPriceScaleId, tier, code }) => {
    try {
      const response = await ClubPriceScalesService.createClubPriceScale({
        operatorId,
        price_scale_id,
        tier,
        clubPriceScaleId,
        code
      });
      const data = response.data;
      return data;
    } catch (e) {
      return e.response.data;
    }
  }
);
export const getClubPriceScales = createAsyncThunk(
  "clubPriceScales/getClubPriceScales",
  async (operator_id) => {
    const response = await ClubPriceScalesService.getClubPriceScales(operator_id);
    const data = response.data;
    return data;
  }
);
export const deleteClubPriceScale = createAsyncThunk(
  "clubPriceScales/deleteClubPriceScale",
  async (id) => {
    try {
      const response = await ClubPriceScalesService.deleteClubPriceScale(id);
      const data = response.data;
      data["id"] = id;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getClubPriceScale = createAsyncThunk(
  "clubPriceScales/getClubPriceScale",
  async (id) => {
    const response = await ClubPriceScalesService.getClubPriceScale(id);
    const data = response.data;
    return data;
  }
);

export const groupTicketPriceScales = createAsyncThunk(
  'tickets/groupTicketPriceScales',
  async (payload) => {
    try {
      const response = await ClubPriceScalesService.groupTicketPriceScales(payload);
      const data = await response.data;
      return data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const clubPriceScalesSlice = createSlice({
  name: "clubPriceScales",
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [createClubPriceScale.pending]: (state) => {
      state.loading = true;
    },
    [createClubPriceScale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createClubPriceScale.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = action.payload.message
    },
    [getClubPriceScales.pending]: (state) => {
      state.loading = true;
    },
    [getClubPriceScales.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getClubPriceScales.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [deleteClubPriceScale.pending]: (state) => {
      state.loading = true;
    },
    [deleteClubPriceScale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteClubPriceScale.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getClubPriceScale.pending]: (state) => {
      state.loading = true;
    },
    [getClubPriceScale.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getClubPriceScale.fulfilled]: (state, action) => {
      state.loading = false;
      state.clubPriceScales = action.payload;
    },
    [groupTicketPriceScales.pending]: (state) => {
      state.loading = true;
    },
    [groupTicketPriceScales.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [groupTicketPriceScales.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    }
  },
});

export default clubPriceScalesSlice.reducer;
