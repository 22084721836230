/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NotesService from '../services/NotesService';

export const createNote = createAsyncThunk(
  'notes/createNote',
  async ({ description, notable_type, notable_id}) => {
    try {
      const response = await NotesService.createNote({ description, notable_type, notable_id });
      const data = await response.data;
      return data;
    } catch (e) {
      throw new Error(e.response.data || 'An error Occured. Try again later');
    }
  }
);

export const getAllUserNotes = createAsyncThunk(
    'notes/getAllUserNotes',
    async (userId) => {
      const response = await NotesService.getAllUserNotes(userId);
      const data = response.data;
      return data;
    }
  );

export const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [createNote.pending]: (state) => {
      state.loading = true;
    },
    [createNote.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createNote.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getAllUserNotes.pending]: (state) => {
      state.loading = true;
    },
    [getAllUserNotes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getAllUserNotes.fulfilled]: (state, action) => {
      state.loading = false;
      state.notes = action.payload;
    },
  },
});

export default notesSlice.reducer;
