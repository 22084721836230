/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RateService from '../services/RateService';

export const createRates = createAsyncThunk(
  'rates/createRates',
  async ({
    rateName,
    price,
    unitPrice,
    availableForPurchase,
    availableType,
    published,
    hotelTypeRate,
    rateType,
    locationId,
    duration,
    validForDurationFrequency,
    rateStart,
    rateEnd,
    availableStart,
    availableEnd,
    availableForRedemption,
    redemptionStart,
    redemptionEnd,
    exclusiveRate,
    overnightRate,
    convenienceFeeType,
    convenienceFeeAmount,
    convenienceFeePercentage,
    passcodeDiscountType,
    passcodeDiscountPrice,
    passcodeDiscountPercentage,
    requirePassCodeOnly,
    passCodeMaxLimit,
    passCodeFrequency,
    passCode,
    multiDayRate,
    daysUntilExpiry,
    rateId,
    allowPromocode,
    monthlyRate,
    prorate,
    allDay,
    monthlyRateAvailableForPurchase,
    numberOfPasses,
    numberOfDays,
    flexpassRate,
    flexPassEndDateTime,
    expiryType,
    operatorId,
    zoneId,
    upgradeRate,
    upgradeTierDelta,
    productType
  }) => {
    try {
      const response = await RateService.createRates({
        rateName,
        price,
        unitPrice,
        availableForPurchase,
        availableType,
        published,
        hotelTypeRate,
        rateType,
        locationId,
        duration,
        validForDurationFrequency,
        rateStart,
        rateEnd,
        availableStart,
        availableEnd,
        availableForRedemption,
        redemptionStart,
        redemptionEnd,
        exclusiveRate,
        overnightRate,
        convenienceFeeType,
        convenienceFeeAmount,
        convenienceFeePercentage,
        passcodeDiscountType,
        passcodeDiscountPrice,
        passcodeDiscountPercentage,
        requirePassCodeOnly,
        passCodeMaxLimit,
        passCodeFrequency,
        passCode,
        multiDayRate,
        daysUntilExpiry,
        rateId,
        allowPromocode,
        monthlyRate,
        prorate,
        allDay,
        monthlyRateAvailableForPurchase,
        numberOfPasses,
        numberOfDays,
        flexpassRate,
        flexPassEndDateTime,
        expiryType,
        operatorId,
        zoneId,
        upgradeRate,
        upgradeTierDelta,
        productType
      });
      if (
        typeof response !== 'undefined' &&
        typeof response.data !== 'undefined'
      ) {
        const data = await response.data;
        if (typeof rateId !== 'undefined') {
          data['rateId'] = rateId;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const getRates = createAsyncThunk('rates/getRates', async () => {
  const response = await RateService.getRates();
  const data = response.data;
  return data;
});

export const getRateDetails = createAsyncThunk(
  'rates/getRateDetails',
  async (rateId) => {
    const response = await RateService.getRatesDetails(rateId);
    const data = response.data;
    return data;
  }
);

export const getRatesHistory = createAsyncThunk(
  "rates/getRatesHistory",
  async ({ rateId, timezoneOffset }) => {
    const response = await RateService.getRatesHistory({ rateId, timezoneOffset });
    const data = response.data;
    return data;
  }
);

export const checkValidFlexPass = createAsyncThunk(
  "rates/checkValidFlexPass",
  async ({ location_id }) => {
    const response = await RateService.checkValidFlexPass({ location_id });
    const data = response.data;
    return data;
  }
);

export const ratesSlice = createSlice({
  name: 'rates',
  initialState: {
    loading: false,
    error: null,
    data: [],
    rate: null,
  },
  extraReducers: {
    [createRates.pending]: (state) => {
      state.loading = true;
    },
    [createRates.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createRates.fulfilled]: (state, action) => {
      if (typeof action.payload.rateId !== 'undefined') {
        state.loading = false;
        let rates = state.data.filter(
          (rate) => rate.id !== action.payload.rateId
        );
        rates.push(action.payload.rate);
        state.data = rates;
      } else if (typeof action.payload.message !== 'undefined') {
        state.loading = false;
      } else {
        state.loading = false;
        state.data.push(action.payload.rate);
      }
    },
    [getRates.pending]: (state) => {
      state.loading = true;
    },
    [getRates.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getRates.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload.data.rate;
    },
    [getRateDetails.pending]: (state) => {
      state.loading = true;
    },
    [getRateDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getRateDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.rate = action.payload;
    },
    [getRatesHistory.pending]: (state) => {
      state.loading = true;
    },
    [getRatesHistory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getRatesHistory.fulfilled]: (state, action) => {
      state.loading = false;
      state.ratesHistory = action.payload;
    },
    [checkValidFlexPass.pending]: (state) => {
      state.flexpassLoading = true;
    },
    [checkValidFlexPass.rejected]: (state, action) => {
      state.flexpassLoading = false;
      state.error = action.error.message;
    },
    [checkValidFlexPass.fulfilled]: (state, action) => {
      state.flexpassLoading = false;
      state.data = action.payload;
    },
  },
});

export default ratesSlice.reducer;
