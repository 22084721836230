/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ParkerVehiclesService from '../services/ParkerVehiclesService';

export const createParkerVehicle = createAsyncThunk(
  'parkerVehicles/createParkerVehicle',
  async ({
    user_id,
    license_plate_number,
    vehicle_color,
    state,
    vehicle_model,
    vehicleId,
    default_vehicle
  }) => {
    try {
      const response = await ParkerVehiclesService.createParkerVehicle({
        user_id,
        license_plate_number,
        vehicle_color,
        state,
        vehicle_model,
        vehicleId,
        default_vehicle
      });
      if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
      const data = await response.data;
      if (typeof vehicleId !== 'undefined') {
        data['vehicleId'] = vehicleId;
      }
      return data;
    }
    } catch (e) {
      throw new Error(e.response.data || 'An error Occured. Try again later');
    }
  }
);

export const getUserSpecificParkerVehicles = createAsyncThunk(
    'parkerVehicles/getUserSpecificParkerVehicles',
    async (userId) => {
      const response = await ParkerVehiclesService.getUserSpecificParkerVehicles(userId);
      const data = response.data;
      return data;
    }
  );

export const getCurrentUserVehicles = createAsyncThunk(
    'parkerVehicles/getCurrentUserVehicles',
    async (userId) => {
      const response = await ParkerVehiclesService.getCurrentUserVehicles(userId);
      const data = response.data;
      return data;
    }
  );

export const getVehicle = createAsyncThunk(
    'parkerVehicles/getVehicle',
    async (vehicleId) => {
      const response = await ParkerVehiclesService.getVehicle(vehicleId);
      const data = response.data;
      return data;
    }
  );
  
export const deleteVehicle = createAsyncThunk(
    "parkerVehicles/deleteVehicle",
    async (vehicleId) => {
      try {
        const response = await ParkerVehiclesService.deleteVehicle(vehicleId);
        const data = response.data;
        data["vehicleId"] = vehicleId;
        return data;
      } catch (e) {
        throw new Error(e.response.data || "An error Occured. Try again later");
      }
    }
  );
export const parkerVehicles = createSlice({
  name: 'parkerVehicles',
  initialState: {
    loading: false,
    error: null,
    data: null,
  },
  extraReducers: {
    [createParkerVehicle.pending]: (state) => {
      state.loading = true;
    },
    [createParkerVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [createParkerVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [getUserSpecificParkerVehicles.pending]: (state) => {
      state.loading = true;
    },
    [getUserSpecificParkerVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getUserSpecificParkerVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.parkerVehicles = action.payload?.vehicles;
    },
    [getCurrentUserVehicles.pending]: (state) => {
      state.loading = true;
    },
    [getCurrentUserVehicles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getCurrentUserVehicles.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUserVehicles = action.payload?.vehicle;
    },
    [getVehicle.pending]: (state) => {
      state.loading = true;
    },
    [getVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
    },
    [deleteVehicle.pending]: (state) => {
      state.loading = true;
    },
    [deleteVehicle.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteVehicle.fulfilled]: (state, action) => {
      state.loading = false;
      state.currentUserVehicles = state?.currentUserVehicles?.filter(
        (vehicle) => vehicle.id !== action.payload.vehicleId
      );
    },
  },
});

export default parkerVehicles.reducer;
