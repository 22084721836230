import ApiService from './ApiService';

export default class PromoCodeService {

  static createPermitGroup = ({
    permitGroupId,
    locationId,
    locationName,
    id,
  }) => {
    let mappedData = {
      permit_group_id: permitGroupId,
      location_id: locationId,
      location_name: locationName,
    };
    if (typeof id !== 'undefined') {
      return ApiService.put(`/permit_groups/${id}`, mappedData);
    } else {
      return ApiService.post('/permit_groups', mappedData);
    }
  };

  static uploadCsv = async ({ fileData, csvType }) => {
    let mappedData = {
      csv_file: fileData,
      csv_type: csvType,
    };
    return ApiService.upload("/permit_groups/upload_csv", mappedData);
  };

  static getPermitGroup = async (id) =>
    ApiService.get(`/permit_groups/${id}`);

  static getPermitGroups = () => ApiService.get('/permit_groups');

  static deletePermitGroup = (id) =>
    ApiService.delete(`/permit_groups/${id}`);
}
