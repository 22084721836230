import ApiService from "./ApiService";

export default class IntegrationsService {
  static createIntegration = ({
    systemName,
    operators,
    locations,
    enabled,
    integrationTypeCd,
    passportOperatorId,
    passportClient,
    passportSecret,
    passportZoneId,
    apiKey,
    parisCustomerCode,
    parisUserId,
    parisUserPassword,
    integrationId,
    t2Email,
    t2Password,
    mpsEmail,
    mpsToken,
  }) => {
    let mappedData = {
      integration: {
        system_name: systemName?.value,
        operator_id: operators?.value,
        location_id: locations?.value,
        enabled,
        integration_type_cd: integrationTypeCd?.value,
        passport_operator_id: passportOperatorId,
        passport_client: passportClient,
        passport_secret: passportSecret,
        passport_zone_id: passportZoneId,
        key: apiKey,
        paris_customer_code: parisCustomerCode,
        paris_user_id: parisUserId,
        paris_user_password: parisUserPassword,
        id: integrationId,
        t2_email: t2Email,
        t2_password: t2Password,
        mps_email: mpsEmail,
        mps_token: mpsToken,
      },
    };
    if (typeof integrationId !== "undefined") {
      return ApiService.put(`/integrations/${integrationId}`, mappedData);
    } else {
      return ApiService.post("/integrations", mappedData);
    }
  };

  static getIntegrations = () => ApiService.get("/integrations");
  static getIntegration = ({ integrationId }) =>
    ApiService.get(`/integrations/${integrationId}`);

  static getFormValues = () => ApiService.get("/integration/form_values");

  static deleteIntegration = (id) => ApiService.delete(`/integrations/${id}`);

  static updateIntegration = async (id, data) =>
    ApiService.patch(`/integrations/${id}`, data);
}
