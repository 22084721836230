/* eslint-disable no-param-reassign */
import { width } from '@mui/system';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cssTransition, toast } from 'react-toastify';
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';

export const authenticate = createAsyncThunk(
  'user/authenticate',
  async ({ email, password }) => {
    try {
      const response = await AuthService.login({ user: { email, password } });
      const data = await response.data;
      if (response.status >= 400 && response.status < 600) {
        throw new Error(data.failure);
      }
      const role = data.user.roles.length ? data.user.roles[0] : null;
      const token = response.headers.authorization;
      localStorage.setItem(
        'user',
        JSON.stringify({
          accessToken: token,
        })
      );
      localStorage.setItem("role", role);
      return data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const userInfo = createAsyncThunk('user/userInfo', async () => {
  const response = await AuthService.userInfo();
  const data = await response.data;
  if (response.status >= 400 && response.status < 600) {
    throw new Error(data.failure);
  }
  return data;
});

export const register = createAsyncThunk(
  'user/register',
  async ({ firstName: first_name, lastName: last_name, email, contactNo: contact_no, password }) => {
    try {
      const response = await UserService.createUser({
        user: { first_name, last_name, email, password, contact_no },
      });
      const data = await response.data;
      if (response.status >= 400 && response.status < 600) {
        throw new Error(data.failure);
      }

      const token = response.headers.authorization;
      localStorage.setItem(
        'user',
        JSON.stringify({
          accessToken: token,
        })
      );
      return data;
    } catch (e) {
      return e.response.data;
    }
  }
);

export const reset = createAsyncThunk(
  'user/reset',
  async (user) => {
    try {
      const response = await UserService.resetUser({ user });
      const data = { 'data': response.data, 'status': response.status }
      return data;
    } catch (e) {
      return e.response;
    }
  }
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async ({ old_password, new_password, new_password_confirmation }) => {
    try {
      const response = await UserService.changePassword(
        { user: { old_password, new_password, new_password_confirmation } }
      );
      const data = await response.data;
      return data;
    } catch (e) {
      return e.response;
    }
  }

);

const bounce = cssTransition({
  enter: 'animate__animated animate__easeIn',
  exit: 'animate__animated animate__easeOut',
  duration: 15000,
  property: width,
  delay: 15000,
});

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    error: null,
    data: {},
    isAuth: localStorage.getItem('user') ? true : false,
    role: null,
    userInfoLoading: false,
  },
  reducers: {
    logout: () => ({
      loading: false,
      error: null,
      data: null,
      isAuth: false,
    }),
  },
  extraReducers: {
    [authenticate.pending]: (state) => {
      state.loading = true;
    },
    [authenticate.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.error.message, {
        transition: bounce,
        autoClose: false,
        position: 'top-right',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      state.error = action.error.message;
    },
    [authenticate.fulfilled]: (state, action) => {
      state.loading = false;
      if (typeof action.payload.user !== 'undefined') {
        state.isAuth = true;
        state.data = {
          userId: action?.payload?.user?.id,
          firstName: action?.payload?.user?.first_name,
          lastName: action?.payload?.user?.last_name,
          email: action?.payload?.user?.email,
          role: 'admin'
        };
      } else {
        toast.error(action.payload);
      }
    },
    [userInfo.pending]: (state) => {
      state.userInfoLoading = true;
    },
    [userInfo.rejected]: (state, action) => {
      state.userInfoLoading = false;
      toast.error(action.error.message);
      state.error = action.error.message;
    },
    [userInfo.fulfilled]: (state, action) => {
      state.userInfoLoading = false;
      state.data = action.payload;
      state.role = action.payload?.roles?.length && action.payload?.roles[0];
    },

    [register.pending]: (state) => {
      state.loading = true;
    },
    [register.rejected]: (state, action) => {
      state.loading = false;
      toast.error(action.error.message);
      state.error = action.error.message;
    },
    [register.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuth = true;
      if (action.payload?.message === 'ok') {
        state.data = {
          userId: action.payload.user.id,
          firstName: action.payload.user.first_name,
          lastName: action.payload.user.last_name,
          email: action.payload.user.email,
        };
      }
    },
    [reset.pending]: (state) => {
      state.loading = true;
    },
    [reset.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [reset.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [changePassword.pending]: (state) => {
      state.loading = true;
    },
    [changePassword.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [changePassword.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
