import ApiService from './ApiService';

export default class MarketService {

  static createMarket = ({
    marketName: name,
    marketLeadName: lead_name,
    marketLeadcontactNo: lead_contact,
    marketId
  }) => {
    let mappedData = {
      name,
      lead_name,
      lead_contact
    };
    if (typeof marketId !== 'undefined') {
      return ApiService.put(`/markets/${marketId}`, mappedData);
    } else {
      return ApiService.post('/markets', mappedData);
    }
  };

  static getMarket = async (id) =>
    ApiService.get(`/markets/${id}`);

  static getMarkets = () => ApiService.get('/markets');

  static deleteMarket = (id) =>
    ApiService.delete(`/markets/${id}`);
}
