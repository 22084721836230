import ApiService from "./ApiService";

export default class LocationService {
  static createLocationVehicle = ({
    plateNumber,
    stateProvince,
    description,
    listType,
    locations,
    vehicleId,
    operators,
  }) => {
    const mappedData = {
      vehicle_plate: {
        plate_number: plateNumber,
        state_province: stateProvince,
        description,
        list_type: listType,
      },
      location_ids: locations?.map((lo) =>
        typeof lo === "object" ? lo?.value : lo
      ),
      operator_ids: operators?.map((op) =>
        typeof op === "object" ? op?.value : op
      ),
      vehicleId,
    };
    if (typeof vehicleId !== "undefined") {
      return ApiService.put(`/vehicle_plates/${vehicleId}`, mappedData);
    } else {
      return ApiService.post("/vehicle_plates", mappedData);
    }
  };

  static uploadVehiclesCsv = async ({
    fileData,
    operators,
    locations,
    csvType,
  }) => {
    let mappedData = {
      csv_file: fileData,
      location_ids: JSON.stringify(locations.map((loc) => typeof loc === 'object' ?  loc.value : loc)),
      operator_ids: JSON.stringify(operators.map((opt) => typeof opt === 'object' ? opt.value : opt)),
      csv_type: csvType,
    };
    return ApiService.upload("/vehicle_plates/upload_csv", mappedData);
  };

  static getAllVehicles = async () => ApiService.get("/vehicle_plates");
  static getLocationVehicle = async (vehicleId) =>
    ApiService.get(`/vehicle_plates/${vehicleId}`);

  static deleteLocationVehicle = async (vehicleId) =>
    ApiService.delete(`/vehicle_plates/${vehicleId}`);

  static updateVehicleListType = async (vehicleId, data) =>
    ApiService.patch(`/vehicle_plates/${vehicleId}`, data);
}
