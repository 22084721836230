import ApiService from './ApiService';

export default class RateService {
  // this is input from user
  static createRates = ({
    rateName,
    price,
    unitPrice,
    availableForPurchase,
    availableType,
    published,
    hotelTypeRate,
    rateType,
    locationId,
    duration,
    validForDurationFrequency,
    rateStart,
    rateEnd,
    availableStart,
    availableEnd,
    availableForRedemption,
    redemptionStart,
    redemptionEnd,
    exclusiveRate,
    overnightRate,
    convenienceFeeType,
    convenienceFeeAmount,
    convenienceFeePercentage,
    passcodeDiscountType,
    passcodeDiscountPrice,
    passcodeDiscountPercentage,
    requirePassCodeOnly,
    passCodeMaxLimit,
    passCodeFrequency,
    passCode,
    multiDayRate,
    daysUntilExpiry,
    rateId,
    allowPromocode,
    monthlyRate,
    prorate,
    allDay,
    monthlyRateAvailableForPurchase,
    numberOfPasses,
    numberOfDays,
    flexpassRate,
    flexPassEndDateTime,
    expiryType,
    operatorId,
    zoneId,
    upgradeRate,
    upgradeTierDelta,
    productType
  }) => {
    // this is the input for the api
    const mappedData = {
      rate_name: rateName,
      price,
      unit_price: unitPrice,
      available_for_purchase: availableForPurchase,
      available_type: availableType,
      published,
      hotel_flag: hotelTypeRate,
      rate_type: rateType,
      duration,
      valid_for_duration_frequency: validForDurationFrequency,
      location_id: locationId,
      rate_start: rateStart,
      rate_end: rateEnd,
      available_start: availableStart,
      available_end: availableEnd,
      available_for_redemption: availableForRedemption,
      redemption_start: redemptionStart,
      redemption_end: redemptionEnd,
      exclusive_rate: exclusiveRate,
      overnight_rate: overnightRate,
      convenience_fee_type: convenienceFeeType,
      dollar_amount: convenienceFeeAmount,
      percent: convenienceFeePercentage,
      passcode_discount_type: passcodeDiscountType,
      passcode_discount_price: passcodeDiscountPrice,
      passcode_discount_percentage: passcodeDiscountPercentage,
      pass_code_only: requirePassCodeOnly,
      pass_code_max_limit: passCodeMaxLimit,
      pass_code_frequency: passCodeFrequency,
      pass_code: passCode,
      is_multi_day_rate: multiDayRate,
      days_until_expiry: daysUntilExpiry,
      allow_promocode: allowPromocode,
      monthly_rate: monthlyRate,
      prorate,
      all_day: allDay,
      monthly_rate_available_for_purchase: monthlyRateAvailableForPurchase,
      number_of_passes: numberOfPasses,
      number_of_days: numberOfDays,
      flexpass_rate: flexpassRate,
      flex_pass_end_date_time: flexPassEndDateTime,
      expiry_type: expiryType,
      operator_id: operatorId,
      zone_id: zoneId,
      upgrade_rate: upgradeRate,
      upgrade_tier_delta: upgradeTierDelta,
      product_type: productType
    };
    if (typeof rateId !== 'undefined') {
      return ApiService.put(`/rates/${rateId}`, mappedData);
    } else {
      return ApiService.post('/rates', mappedData);
    }
  };

  static getRates = (filters) => ApiService.get('/rates', filters);

  static deleteRate = (rateId) => ApiService.delete(`/rates/${rateId}`);

  static getRatesDetails = (rateId) => ApiService.get(`/rates/${rateId}`);

  static updateRate = async (id, data) =>
    ApiService.patch(`/rates/${id}`, data);

  static updateRateOrder = async (data) =>
    ApiService.patch(`location/update_rates`, data);

  static getUpdatedRates = (location_id) => ApiService.get(`/locations/rates/${location_id}`);

  static checkValidFlexPass = ({ location_id }) =>
    ApiService.get(`/check_valid_flex_pass/${location_id}`);

  static getRatesHistory = async ({ rateId, timezoneOffset }) => ApiService.get(`rates/changes_history/${rateId}?timezone_offset=${timezoneOffset}`);
}
