/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MarketService from '../services/MarketService';

export const createMarket = createAsyncThunk(
  'markets/createMarket',
  async ({ marketName, marketLeadName, marketLeadcontactNo, marketId }) => {
    try {
      const response = await MarketService.createMarket({
        marketName,
        marketLeadName,
        marketLeadcontactNo,
        marketId,
      });
      if (
        typeof response !== 'undefined' &&
        typeof response.data !== 'undefined'
      ) {
        const data = await response.data;
        if (typeof marketId !== 'undefined') {
          data['marketId'] = marketId;
        }
        return data;
      }
    } catch (e) {
      return e.response.data;
    }
  }
);

export const deleteMarket = createAsyncThunk(
  'markets/deleteMarket',
  async (market) => {
    const { id: marketId } = market;
    const response = await MarketService.deleteMarket(marketId);
    const data = response.data;
    data['marketId'] = marketId;
    return data;
  }
);

export const getMarket = createAsyncThunk('markets/getMarket', async (id) => {
  const response = await MarketService.getMarket(id);
  const data = response.data;
  return data;
});

export const getMarkets = createAsyncThunk('markets/getMarkets', async () => {
  const response = await MarketService.getMarkets();
  const data = response.data;
  return data;
});

export const marketsSlice = createSlice({
  name: 'markets',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  extraReducers: {
    [createMarket.pending]: (state) => {
      state.loading = true;
    },
    [createMarket.rejected]: (state, action) => {
      state.loading = false;
    },
    [createMarket.fulfilled]: (state, action) => {
      if (typeof action.payload.marketId !== 'undefined') {
        state.loading = false;
        let markets = state.data.filter(
          (market) => market.id !== action.payload.marketId
        );
        markets.push(action.payload);
        state.data = markets;
      } else if (typeof action.payload.message !== 'undefined') {
        state.loading = false;
      } else {
        state.loading = false;
        state.data.push(action.payload);
      }
    },
    [deleteMarket.pending]: (state) => {
      state.loading = true;
    },
    [deleteMarket.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [deleteMarket.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = state.data.filter(
        (market) => market.id !== action.payload.marketId
      );
    },
    [getMarket.pending]: (state) => {
      state.loading = true;
    },
    [getMarket.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getMarket.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = [action.payload];
    },
    [getMarkets.pending]: (state) => {
      state.loading = true;
    },
    [getMarkets.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getMarkets.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export default marketsSlice.reducer;
