import ApiService from "./ApiService";

export default class ClubPriceScalesService {
  static createClubPriceScale = ({
    operatorId,
    price_scale_id,
    tier,
    clubPriceScaleId,
    code
  }) => {
    let mappedData = {
      operator_id: operatorId,
      code,
      price_scale_id,
      tier
    };
    if (typeof clubPriceScaleId !== "undefined") {
      return ApiService.put(`/club_price_scales/${clubPriceScaleId}`, mappedData);
    } else {
      return ApiService.post("/club_price_scales", mappedData);
    }
  };

  static getClubPriceScales = async (operator_id) =>
    ApiService.get(`/club_price_scales/group/${operator_id}`);

  static getClubPriceScale = async (id) => ApiService.get(`/club_price_scales/${id}`);

  static deleteClubPriceScale = (id) => ApiService.delete(`/club_price_scales/${id}`);

  static groupTicketPriceScales = async (payload) =>
    ApiService.get('/group_ticket_price_scale', payload);
}
