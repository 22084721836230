import { createSlice } from '@reduxjs/toolkit';

const initialState = {};
const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (_state, action) => ({ message: action.payload }),
    clearMessage: () => ({ message: '' }),
  },
});
// const { reducer, actions } = messageSlice;
export const { setMessage, clearMessage } = messageSlice.actions;
export default messageSlice.reducer;
