/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from '../services/UserService';

export const createUser = createAsyncThunk(
    'users/createUser',
    async ({
        email,
        firstName,
        lastName,
        nonEmail,
        password,
        contactno,
        userRole,
        operators,
        locations,
        userId,
        platform
    }) => {
        try {
            const response = await UserService.createUserService(
                email,
                firstName,
                lastName,
                nonEmail,
                password,
                contactno,
                userRole,
                operators,
                locations,
                userId,
                platform
            );
            if (typeof response !== 'undefined' && typeof response.data !== 'undefined') {
                const data = await response.data;
                if (typeof userId !== 'undefined') {
                    data['userId'] = userId;
                }
                data['status'] = response.status;
                return data;
            }
        } catch (e) {
            return e.response.data;
        }
    }
);

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (userId) => {
        try {
            const response = await UserService.deleteUser(userId);
            const data = response.data;
            data['userId'] = userId;
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
)

export const getUsers = createAsyncThunk(
    'users/getUsers',
    async () => {
        const response = await UserService.getUsers();
        const data = response.data;
        return data;
    }
);

export const getUserAllRoles = createAsyncThunk(
    'users/getUserAllRoles',
    async () => {
        const response = await UserService.getUserAllRoles();
        const data = response.data;
        return data;
    }
);

export const getUser = createAsyncThunk(
    'users/getUser',
    async (userId) => {
        const response = await UserService.getUser(userId);
        const data = response.data;
        return data;
    }
);

export const getAccountsHistory = createAsyncThunk(
    'users/getAccountsHistory',
    async ({ accountsId, timezoneOffset }) => {
        const response = await UserService.getAccountsHistory({ accountsId, timezoneOffset });
        const data = response.data;
        return data;
    }
);

export const getParkerTransactionHistory = createAsyncThunk(
    "users/getParkerTransactionHistory",
    async () => {
        const response = await UserService.getParkerTransactionHistory();
        const data = response.data;
        return data;
    }
);

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        loading: false,
        error: null,
        data: [],
        user: null,
        id: null,
        users: [],
        accountsHistory: []
    },
    extraReducers: {
        [createUser.pending]: (state) => {
            state.loading = true;
        },
        [createUser.rejected]: (state, action) => {
            state.loading = false;
        },
        [createUser.fulfilled]: (state, action) => {
            if (typeof action.payload.userId !== 'undefined') {
                state.loading = false;
                let users = state.data.filter((user) => user.id !== action.payload.userId);
                users.push(action.payload);
                state.data = users;
            } else if (typeof action.payload.message !== 'undefined') {
                state.loading = false;
            } else {
                state.loading = false;
                state.data.push(action.payload);
            }
        },
        [deleteUser.pending]: (state) => {
            state.loading = true;
        },
        [deleteUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.users.users = Object.entries(state.users)[0][1].filter((user) => user.id !== action.payload.userId);
        },
        [getUsers.pending]: (state) => {
            state.loading = true;
        },
        [getUsers.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.loading = false;
            state.users = action.payload;
        },
        [getUser.pending]: (state) => {
            state.loading = true;
        },
        [getUser.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getUser.fulfilled]: (state, action) => {
            state.loading = false;
            state.user = action.payload;
        },
        [getUserAllRoles.pending]: (state) => {
            state.loading = true;
        },
        [getUserAllRoles.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getUserAllRoles.fulfilled]: (state, action) => {
            state.loading = false;
            state.roles = action.payload;
        },
        [getAccountsHistory.pending]: (state) => {
            state.loading = true;
        },
        [getAccountsHistory.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getAccountsHistory.fulfilled]: (state, action) => {
            state.loading = false;
            state.accountsHistory = action.payload;
        },
        [getParkerTransactionHistory.pending]: (state) => {
            state.loading = true;
        },
        [getParkerTransactionHistory.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        },
        [getParkerTransactionHistory.fulfilled]: (state, action) => {
            state.loading = false;
            state.parkers = action.payload;
        },
    },
});

export default usersSlice.reducer;